const NotFound = () => {
  return (
    <div className="get-order-info">
      <div className="section-header">
        <h2 className="info-header">Not Found...</h2>
      </div>
    </div>
  );
};

export default NotFound;
