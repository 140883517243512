// import readFromDB from "../../firebase/readFromDB";
import "../../styles/Dashboard.css";
import { useNavigate } from "react-router";
// import writeToDB from "../../firebase/writeToDB";

const Dashboard = ({
  setOrderInformation,
  setResultInformation,
  setCustomerInformation,
  setCustomeOrderInformation,
  setotherInformation,
  setAccessoriesInformation,
  setEditOrderBlinds,
  setResetData,
  resetData,
  PoNumber,
  setPoNumber,
  setStatus,
}) => {
  const history = useNavigate();

  // const poMaker = async () => {
  //   try {
  //     const data = await readFromDB("counters"); // Await the promise to resolve
  //     let poCode = data.poCounters;
  //     writeToDB(poCode + 1, "counters", "poCounters");

  //     poCode = poCode + 1001;

  //     const now = new Date();
  //     const year = now.getFullYear().toString().slice(-2);
  //     const month = ("0" + (now.getMonth() + 1)).slice(-2);
  //     const day = ("0" + now.getDate()).slice(-2);

  //     const dateTime = `${year}${month}${day}`;

  //     const newPONumber = `${poCode}-${dateTime}-${PoNumber.userId}`;

  //     setPoNumber({ userId: PoNumber.userId, username: PoNumber.username, po: poCode, poNumber: newPONumber });
  //   } catch (error) {
  //     console.error("Error fetching data:", error); // Handle any errors
  //   }
  // };

  const onClickHandler = (e) => {
    if (e.target.getAttribute("data-value") === "true") {
      if (resetData) {
        setOrderInformation({});
        setResultInformation({});
        setCustomerInformation({
          name: "",
          address: "",
          postalCode: "",
          city: "",
          province: "",
          email: "",
          phone: "",
          counsultationDate: "",
          orderDate: "",
          salesRep: PoNumber.userId,
          quotationNumber: "",
          refferalSource: "",
          deliveryInstallationDate: "",
        });
        setotherInformation({
          discount: 0,
          payment: 0,
          installation: 0,
          delivery: 0,
          tax: 13,
          paymentFee: 0,
          paymentType: "etransfer",
        });
        setAccessoriesInformation({});
        setCustomeOrderInformation({});
        setEditOrderBlinds({});
        setResetData(false);
        setStatus({
          status: "quotation",
          poNumber: null,
          salesRep: PoNumber.userId,
          payment: false,
        });
        // console.log("Passed");
        // poMaker();
      }
    } else if (e.target.getAttribute("data-value") === "false") {
      setResetData(false);
      // history.goBack(); navigate(-1);
      history(-1);
    }
  };

  return (
    <>
      {resetData && (
        <div className="reset-popup">
          <span>Are You Sure to Reset all Data?</span>
          <div>
            <button data-value="true" onClick={(e) => onClickHandler(e)}>
              YES
            </button>
            <button data-value="false" onClick={(e) => onClickHandler(e)}>
              No
            </button>
          </div>
        </div>
      )}
      {/* <div className="dashboard-info">
        <h2>For New Order, Follow These Steps:</h2>
        <ul>
          <li>1 - Add Customer Info</li>
          <li>2 - Add Blinds Details</li>
          <li>3 - Add Accessories (If Needed)</li>
          <li>4 - Check Details in Checkout</li>
          <li>5 - Save File</li>
          <li>6 - Export PDF</li>
          <li>7 - Report to Office</li>
        </ul>
        <h2>To Check Previous Orders:</h2>
        <p>Press Orders List</p>
        <h2>To Check Customers Info:</h2>
        <p>Press Customers List</p>
        <h2>To Reset All Forms and Start Again:</h2>
        <p>Press Reset All</p>
        <h2>For More Information:</h2>
        <p>Contact: info@blindsfactory.ca</p>
      </div> */}

      <div className="contact-us">
        <img src={"resources/images/nilshades-logo.png"} alt="Nili Group"></img>
        <p>
          Welcome to NilShades, your streamlined solution for managing blinds sales, franchises, and dealer operations.
          From pricing to customer relations, NilShades provides everything you need in one intuitive platform.
        </p>
        <a href="https://niliinteriors.com">www.nilshades.app</a>
        <a href="mailto:info@niliinteriors.com"> info@nilshades.app</a>
        <a href="https://maps.app.goo.gl/MBzqbAgrqcoi4mF98">
          <address>Toronto, Ontario, Canada</address>
        </a>
      </div>

      {/* <div className="contact-us">
        <img src={"resources/images/nilshades-logo.png"} alt="Nili Group"></img>
        <p>
          Welcome to NilShades, your all-in-one solution for managing blinds sales, franchises, and dealer operations.
          Designed with efficiency in mind, NilShades streamlines every step of the process, from product price
          calculations to accounting, customer relationship management, and task tracking. Whether you're managing
          orders, overseeing supplier progress, or handling franchise growth, our platform provides the tools you need
          to succeed. With user-friendly features and real-time updates, NilShades empowers you to stay ahead in the
          window coverings industry, all in one intuitive web application.
        </p>
        <a href="https://niliinteriors.com">www.nilshades.app</a>
        <a href="mailto:info@niliinteriors.com"> info@nilshades.app</a>
        <a href="https://maps.app.goo.gl/MBzqbAgrqcoi4mF98">
          {" "}
          <address>Toronto, Ontario, Canada</address>
        </a>
        <a href="tel:+16475704600">+1(647) 570 4600</a>
      </div> */}
    </>
  );
};

export default Dashboard;
