export const ROLE_DEVELOPPER = 100;
export const ROLE_MANAGER = 101;
export const ROLE_ADMINISTRATOR = 102;
export const ROLE_INSTALLER = 103;
export const ROLE_MEASURER = 104;
export const ROLE_SALES = 105;
export const ROLE_ACCOUNTANT = 106;
export const ROLE_CONSULTANT = 107;
export const ROLE_SUPPLIER = 108;
export const ROLE_DEALER = 109;
export const ROLE_FRANCHISE = 110;
export const ROLE_TRAINER = 111;
export const ROLE_CUSTOMER = 112;
export const ROLE_VISITOR = 113;
