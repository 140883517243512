import { database, set, ref } from "./config";

const writeToDB = async (data, type, id) => {
  if (type) {
    try {
      await set(ref(database, `${type}/${id}`, id), data);
    } catch (error) {
      console.log(error.code);
    }
    // console.log("Send Successed");
  } else {
    alert("Send Failed");
  }
};

export default writeToDB;
