import React, { useState, useEffect } from "react";
import readFromDB from "../../firebase/readFromDB";
import writeToDB from "../../firebase/writeToDB";
import "../../styles/Management.css";

import role from "../../dataBase/users/role.json";
const Management = () => {
  const userRoles = role.roles;
  const [dropDown, setDropDown] = useState({});

  const [userList, setUserList] = useState({});
  const [userListEdited, setUserListEdited] = useState({});

  const updateRole = async (uid, role) => {
    await writeToDB(role, "users", `${uid}/role`);
    getUser();
  };

  const getUser = async () => {
    const users = await readFromDB("users");
    setUserList(users);
    setUserListEdited(users);
    setDropDown({});
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <h2>Access Management</h2>
      <div className="access-wrapper">
        <ul className="user-list">
          {Object.values(userListEdited)
            .sort((a, b) => Number(a.userId) - Number(b.userId)) // Sort in ascending order
            .map((user, index) => {
              return (
                <div key={index}>
                  {Number(user.role) !== 100 && (
                    <li className="user-card">
                      <span className="user-id"> {user.userId}</span>
                      <span className="user-name"> {user.name}</span>
                      <div
                        className="user-role"
                        onClick={() => {
                          if (dropDown[user.userId]) {
                            setDropDown({});
                          } else {
                            setDropDown({ [user.userId]: true });
                          }
                        }}
                      >
                        {userList[user.uid].role !== userListEdited[user.uid].role && (
                          <i
                            className="fa fa-save save-icon"
                            onClick={() => {
                              updateRole(user.uid, user.role);
                            }}
                          ></i>
                        )}
                        <span> {userRoles.find((item) => item.id === user.role)?.title || "Unknown Role"}</span>
                        <i className="fa-solid fa-chevron-down"></i>
                        <div className={`drop-down ${dropDown[user.userId] && "drop-down-opener "} `}>
                          <ul>
                            {userRoles.map((role, index) => {
                              return (
                                <div key={index}>
                                  {Number(role) !== 100 && (
                                    <li
                                      className="drop-down-item"
                                      onClick={() => {
                                        setUserListEdited({
                                          ...userListEdited,
                                          [user.uid]: {
                                            ...userListEdited[user.uid],
                                            role: role.id,
                                          },
                                        });
                                      }}
                                    >
                                      {role.title}
                                    </li>
                                  )}
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <span className="user-creation"> {user.createAccount}</span>
                      <span className="user-phone"> {user.phone}</span>
                      <span className="user-username"> {user.username}</span>
                      <span className="user-postal"> {user.postal}</span>
                      <span className="user-address"> {user.address}</span>
                    </li>
                  )}
                </div>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default Management;
