import { useState, useRef, useEffect } from "react";
import CustomerInformation from "../others/CustomerInformation";
import InvoiceTable from "../others/InvoiceTable";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import "../../styles/Invoice.css";
import { useNavigate } from "react-router";
import writeToDB from "../../firebase/writeToDB";
const Invoice = ({
  customerInformation,
  orderInformation,
  otherInformationHandler,
  customeOrderInformation,
  otherInformation,
  accessoriesInformation,
  editOrderHandlerBlinds,
  editOrderBlinds,
  editOrderAccessories,
  editOrderHandlerAccessories,
  editOrderCustome,
  isEditedOrderHandler,
  editOrderHandlerCustome,
  resultInformationHandler,
  setExportType,
  setUpdatedData,
  deleteOrder,
  ordersList,
  setOrdersList,
  setStatus,
  status,
  PoNumber,
  resultInformation,
}) => {
  const HUB_INSTALLATION_FEE = 49;
  const TRANSFORMER_INSTALLATION_FEE = 149;
  const BLINDS_INSTALLATION_FEE = 14.9;
  const MINIMUM_INSTALLATION_FEE = 199;

  const [minimumInstallation, setMinimumInstallation] = useState(0);
  const [info, setInfo] = useState({});
  const navigate = useNavigate();
  // save button handler
  const saveButtonHandler = () => {
    setInfo({
      customeOrdr: { ...customeOrderInformation },
      customer: { ...customerInformation },
      order: { ...orderInformation },
      accessories: { ...accessoriesInformation },
      result: { ...resultInformation },
      other: { ...otherInformation },
      status: { ...status },
    });
  };

  useEffect(() => {
    if (Object.keys(info).length > 0) {
      if (Object.keys(info.customer).length > 0) {
        if (info.result.subTotal * 1 > 0) {
          writeToDB(JSON.stringify(info), "orders", info.customer.quotationNumber);
          alert("Data Saved!");
        } else {
          alert("Check Info Details!");
        }
      } else {
        alert("Enter Customer Information Before Saving");
      }
    }
  }, [info]);

  // console.log(ordersList);
  useEffect(() => {
    setStatus({
      ...status,
      poNumber: PoNumber.po,
      salesRep: PoNumber.userId,
      payment: false,
    }); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ordersList.state) {
      // console.log(ordersList);
      setOrdersList({
        status: false,
      });
      // console.log(ordersList.orderInfo);
      setUpdatedData(ordersList.orderInfo);
    } // eslint-disable-next-line
  }, [ordersList.state]);

  // Function to handle checkbox change
  const handleCheckboxChangePayments = (e) => {
    const newOtherAmounts = { ...otherAmounts };
    newOtherAmounts.paymentFee = e.target.value;
    newOtherAmounts.paymentType = e.target.getAttribute("name");
    // console.log(newOtherAmounts);
    otherInformationHandler({ ...newOtherAmounts });
    setOtherAmounts({ ...newOtherAmounts });
  };

  // Get Other Amount  and Payment Fee /////////////////////////////////////////////////////////////////////////////////////
  const [paymentTypeShow, setPaymentTypeShow] = useState(false);
  const [otherAmounts, setOtherAmounts] = useState({ ...otherInformation, paymentType: "etransfer", paymentFee: 0 });

  const otherAmountHandler = (e) => {
    const newOtherAmounts = { ...otherAmounts };

    newOtherAmounts[e.target.dataset.name] = e.target.value;

    if (e.target.dataset.name === "discount" && e.target.value > 35) {
      alert("You've reached the maximum discount!");
      newOtherAmounts[e.target.dataset.name] = 35;
    }

    if (e.target.dataset.name === "installation") {
      // newOtherAmounts[e.target.dataset.name] = Number(minimumInstallation) + Number(e.target.value);
      setMinimumInstallation(Number(minimumInstallation) + Number(e.target.value));
    }

    otherInformationHandler({ ...newOtherAmounts });
    setOtherAmounts({ ...newOtherAmounts });
  };

  useEffect(() => {
    setOtherAmounts({ ...otherInformation });
  }, [otherInformation]);
  // pdf generator /////////////////////////////////////////////////////////////////////////////////////////
  const [showExport, setShowExport] = useState(false);

  const salesContractExportHandler = (data) => {
    // console.log("PDF GENERATOR : export-sales-contract");
    // console.log(data);
    setExportType(data);
    navigate("/export");
  };

  // Upload files //////////////////////////////////////////////////////////////////////////////////////////
  const [uploadedData, setUploadedData] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const fileInputRef = useRef(null);
  const handleUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return; // No file selected
    }

    // Check if the file type is JSON
    if (file.type !== "application/json") {
      alert("Please select a Correct file.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const jsonData = JSON.parse(event.target.result);
      setUploadedData(jsonData);
    };
    reader.readAsText(file);
  };

  const addToFormHandler = () => {
    if (uploadedData) {
      setUpdatedData(uploadedData);
      setUploadedData(null);
      fileInputRef.current.value = null;
    }
  };

  // Status Check Box /////////////////////////////////////////////////////////////////////////////////////
  const [showStatusDropDwon, setShowStatusDropDwon] = useState(false);
  const handleCheckboxStatus = (e) => {
    setStatus({ ...status, status: e.target.getAttribute("name") });
  };

  // Installation Calculator
  const installationCalculator = () => {
    let transformerQuantity = 0;
    let hubQuantity = 0;
    let totalBlinds = 0;
    if (orderInformation.length > 0) {
      totalBlinds = orderInformation.reduce((sum, member) => {
        return sum + (Number(member.quantity?.inputData) || 0); // Safely access inputData
      }, 0);
    }

    if (accessoriesInformation.length > 0) {
      accessoriesInformation.forEach((item) => {
        if (item.accessories.id === "105") {
          transformerQuantity += Number(item.quantity?.inputData) || 0;
        } else if (item.accessories.id === "102") {
          hubQuantity += Number(item.quantity?.inputData) || 0;
        }
      });
    }

    let installatinoFee = Math.round(
      HUB_INSTALLATION_FEE * hubQuantity +
        TRANSFORMER_INSTALLATION_FEE * transformerQuantity +
        BLINDS_INSTALLATION_FEE * totalBlinds
    );

    if (installatinoFee < MINIMUM_INSTALLATION_FEE) {
      installatinoFee = MINIMUM_INSTALLATION_FEE;
    }
    setMinimumInstallation(installatinoFee);
    otherInformationHandler({ ...otherAmounts, installation: installatinoFee });
    setOtherAmounts({ ...otherAmounts, installation: installatinoFee });

    // setMinimumInstallation(installatinoFee);
    // otherInformationHandler({ ...otherAmounts, installation: installatinoFee });
    // setOtherAmounts({ ...otherAmounts, installation: installatinoFee });
  };

  // useEffect(() => {
  //   console.log(otherAmounts);
  //   const newOtherAmounts = { ...otherAmounts };
  //   newOtherAmounts.installation = minimumInstallation + otherAmounts.installation;
  //   otherInformationHandler({ ...newOtherAmounts });
  //   setOtherAmounts({ ...newOtherAmounts });
  // }, [minimumInstallation]);

  useEffect(() => {
    if (otherInformation.installation === 0) {
      installationCalculator();
    }
  }, []);
  // JSX //////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="invoice">
      {/* Invoice Menu */}
      <div className="invoice-menu">
        <div className="action-buttons">
          <div className="action-button-dropDown">
            {/* Order Status */}
            <div className="save-button drop-down-btn" onClick={() => setShowStatusDropDwon(!showStatusDropDwon)}>
              <span> {status.status}</span>
              {!showStatusDropDwon && <i className="fa fa-chevron-down"></i>}
              {showStatusDropDwon && <i className="fa fa-chevron-up"></i>}
              {/* order status */}
              {showStatusDropDwon && (
                <ul className="order-status">
                  <li name="quotation" onClick={(e) => handleCheckboxStatus(e)}>
                    <input
                      readOnly
                      type="checkbox"
                      id="quotation"
                      value={0}
                      checked={status.status === "quotation"}
                    ></input>
                    Quotation
                  </li>
                  <li name="sales" onClick={(e) => handleCheckboxStatus(e)}>
                    <input readOnly type="checkbox" id="sales" value={0} checked={status.status === "sales"}></input>
                    Sales
                  </li>
                  <li name="measurement" onClick={(e) => handleCheckboxStatus(e)}>
                    <input
                      readOnly
                      type="checkbox"
                      id="measurement"
                      value={0}
                      checked={status.status === "measurement"}
                    ></input>
                    Measurement
                  </li>
                  <li name="manufacturing" onClick={(e) => handleCheckboxStatus(e)}>
                    <input
                      readOnly
                      type="checkbox"
                      id="manufacturing"
                      value={0}
                      checked={status.status === "manufacturing"}
                    ></input>
                    Manufacturing
                  </li>
                  <li name="shipment" onClick={(e) => handleCheckboxStatus(e)}>
                    <input
                      readOnly
                      type="checkbox"
                      id="shipment"
                      value={0}
                      checked={status.status === "shipment"}
                    ></input>
                    Shipment
                  </li>
                  <li name="installation" onClick={(e) => handleCheckboxStatus(e)}>
                    <input
                      readOnly
                      type="checkbox"
                      id="installation"
                      value={0}
                      checked={status.status === "installation"}
                    ></input>
                    Installation
                  </li>{" "}
                  <li name="payment" onClick={(e) => handleCheckboxStatus(e)}>
                    <input
                      readOnly
                      type="checkbox"
                      id="payment"
                      value={0}
                      checked={status.status === "payment"}
                    ></input>
                    Payment
                  </li>
                  <li name="finished" onClick={(e) => handleCheckboxStatus(e)}>
                    <input
                      readOnly
                      type="checkbox"
                      id="finished"
                      value={0}
                      checked={status.status === "finished"}
                    ></input>
                    Finished
                  </li>
                </ul>
              )}
            </div>
            {/* Payment Type */}
            <div className="save-button drop-down-btn" onClick={() => setPaymentTypeShow(!paymentTypeShow)}>
              <span>{otherInformation.paymentType}</span>
              {!paymentTypeShow && <i className="fa fa-chevron-down"></i>}
              {paymentTypeShow && <i className="fa fa-chevron-up"></i>}
              {paymentTypeShow && (
                <ul className="payments">
                  <li className="payment-card" name="cash" value={0} onClick={(e) => handleCheckboxChangePayments(e)}>
                    <input
                      readOnly
                      type="checkbox"
                      id="cash"
                      value={0}
                      checked={otherInformation.paymentType === "cash"}
                    ></input>
                    Cash %0
                  </li>

                  <li
                    className="payment-card"
                    name="etransfer"
                    value={0}
                    onClick={(e) => handleCheckboxChangePayments(e)}
                  >
                    <input
                      readOnly
                      type="checkbox"
                      id="etransfer"
                      value={0}
                      checked={otherInformation.paymentType === "etransfer"}
                    ></input>
                    E-Transfer 0%
                  </li>

                  <li className="payment-card" name="debit" value={0} onClick={(e) => handleCheckboxChangePayments(e)}>
                    <input
                      readOnly
                      type="checkbox"
                      id="debit"
                      value={0}
                      checked={otherInformation.paymentType === "debit"}
                    ></input>
                    Debit 0%
                  </li>
                  <li
                    className="payment-card"
                    name="visa"
                    value={2.25}
                    onClick={(e) => handleCheckboxChangePayments(e)}
                  >
                    <input
                      readOnly
                      type="checkbox"
                      id="visa"
                      value={2.25}
                      checked={otherInformation.paymentType === "visa"}
                    ></input>
                    Visa 2.25%
                  </li>

                  <li
                    className="payment-card"
                    name="master"
                    value={2.25}
                    onClick={(e) => handleCheckboxChangePayments(e)}
                  >
                    <input
                      readOnly
                      type="checkbox"
                      id="master"
                      value={2.25}
                      checked={otherInformation.paymentType === "master"}
                    ></input>
                    Master 2.25%
                  </li>

                  <li className="payment-card" name="amex" value={3} onClick={(e) => handleCheckboxChangePayments(e)}>
                    <input
                      readOnly
                      type="checkbox"
                      id="amex"
                      value={3}
                      checked={otherInformation.paymentType === "amex"}
                    ></input>
                    AmEx 0%
                  </li>

                  <li
                    className="payment-card"
                    name="finance"
                    value={5}
                    onClick={(e) => handleCheckboxChangePayments(e)}
                  >
                    <input
                      readOnly
                      type="checkbox"
                      id="finance"
                      value={5}
                      checked={otherInformation.paymentType === "finance"}
                    ></input>
                    Finance 5%
                  </li>
                </ul>
              )}
            </div>

            {/* Upload From Pc Button */}
            <button className="save-button" onClick={() => setShowUpload(true)}>
              <span> Upload File</span>
              <i className="fa fa-upload" onClick={() => setShowUpload(false)}></i>
            </button>
          </div>
          <div className="action-button-action">
            {/* Export Button */}
            <button className="save-button" onClick={() => setShowExport(true)}>
              <i className="fa fa-file-pdf"></i>
              <span>Export</span>
            </button>

            {/* Save Button */}
            <button className="save-button" onClick={() => saveButtonHandler()}>
              <i className="fa fa-save"></i>
              <span> Save</span>
            </button>
          </div>
        </div>
      </div>

      {/* upload data */}
      {showUpload && (
        <div className="upload-data">
          <i className="fa fa-times get-result-closer" onClick={() => setShowUpload(false)}></i>
          <button
            onClick={() => {
              addToFormHandler();
            }}
          >
            Upload Inforamtion
          </button>
          <input type="file" ref={fileInputRef} onChange={(event) => handleUpload(event)} />
        </div>
      )}
      {/* get Result */}

      {showExport && (
        <div className="get-result">
          <i
            className="fa fa-times get-result-closer"
            onClick={() => {
              setShowExport(false);
            }}
          ></i>
          {/* get quotation */}
          <div className="result-card" onClick={() => salesContractExportHandler("QUOTATION")}>
            <i className="fa fa-file-pdf"></i>
            <span>Quotation</span>
          </div>
          {/* get quotation with Size */}
          <div className="result-card" onClick={() => salesContractExportHandler("QUOTATION + SIZE")}>
            <i className="fa fa-file-pdf"></i>
            <span>Quotation + Size</span>
          </div>
          {/* get sales contract */}
          <div className="result-card" onClick={() => salesContractExportHandler("SALES CONTRACT")}>
            <i className="fa fa-file-pdf"></i>
            <span>Sales Contract</span>
          </div>
          {/* get invoice */}
          <div className="result-card" onClick={() => salesContractExportHandler("INVOICE")}>
            <i className="fa fa-file-pdf"></i>
            <span>Invoice</span>
          </div>{" "}
          {/* get factory */}
          <div className="result-card" onClick={() => salesContractExportHandler("FACTORY")}>
            <i className="fa fa-file-pdf"></i>
            <span>Factory From</span>
          </div>
          {/* Delivery Note */}
          <div className="result-card" onClick={() => salesContractExportHandler("Delivery Note")}>
            <i className="fa fa-file-pdf"></i>
            <span>Delivery Note</span>
          </div>
        </div>
      )}

      {/* other amount */}
      <div className="other-amounts">
        <div className="discount-wrapper">
          <label htmlFor="discount">Discount</label>
          <div>
            %
            <input
              data-name="discount"
              id="discount"
              min={0}
              max={35}
              type="number"
              className="discount"
              placeholder="0"
              onChange={(e) => {
                otherAmountHandler(e);
              }}
              value={otherAmounts.discount !== 0 && otherAmounts.discount}
            ></input>
          </div>
        </div>
        <div className="discount-wrapper">
          <label htmlFor="payment">Payments</label>
          <div>
            $
            <input
              data-name="payment"
              id="payment"
              placeholder="0"
              type="number"
              className="payment"
              onChange={(e) => {
                otherAmountHandler(e);
              }}
              value={otherAmounts.payment !== 0 && otherAmounts.payment}
            ></input>
          </div>
        </div>
        <div className="discount-wrapper">
          <i
            className="fa fa-refresh refresh-icon"
            onClick={() => {
              installationCalculator();
            }}
          ></i>
          <label htmlFor="installation">Installation</label>
          <div>
            ${" "}
            <input
              data-name="installation"
              id="installation"
              type="number"
              className="installation"
              placeholder="0"
              min={0}
              onChange={(e) => {
                otherAmountHandler(e);
              }}
              value={otherAmounts.installation !== 0 && otherAmounts.installation}
            ></input>
          </div>
        </div>
        <div className="discount-wrapper">
          <label htmlFor="delivery">Other</label>
          <div>
            ${" "}
            <input
              data-name="delivery"
              id="delivery"
              type="number"
              className="delivery"
              placeholder="0"
              onChange={(e) => {
                otherAmountHandler(e);
              }}
              value={otherAmounts.delivery !== 0 && otherAmounts.delivery}
            ></input>
          </div>
        </div>
        {/* <div className="discount-wrapper">
          <label htmlFor="tax">TAX</label>
          <div>
            %
            <input
              data-name="tax"
              id="tax"
              min={0}
              max={100}
              type="number"
              className="tax"
              placeholder="0"
              onChange={(e) => {
                otherAmountHandler(e);
              }}
              value={otherAmounts.tax !== 0 && otherAmounts.tax}
              readOnly
            ></input>
          </div>
        </div> */}
      </div>

      <div className="invoice" id="divToConvert">
        <div className="invoice-wrapper">
          <div className="customer-information">
            <CustomerInformation customerInformation={customerInformation} />
          </div>
          <div className="customer-invoice">
            <InvoiceTable
              orderInformation={orderInformation}
              otherAmounts={otherAmounts}
              customeOrderInformation={customeOrderInformation}
              editOrderHandlerCustome={editOrderHandlerCustome}
              editOrderCustome={editOrderCustome}
              accessoriesInformation={accessoriesInformation}
              editOrderHandlerBlinds={editOrderHandlerBlinds}
              editOrderBlinds={editOrderBlinds}
              editOrderAccessories={editOrderAccessories}
              editOrderHandlerAccessories={editOrderHandlerAccessories}
              isEditedOrderHandler={isEditedOrderHandler}
              resultInformationHandler={resultInformationHandler}
              deleteOrder={deleteOrder}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
