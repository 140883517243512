import "../../styles/AppHeader.css";
import AppMenu from "./AppMenu";

const AppHeader = ({ setResetData, authenticatedUser }) => {
  return (
    <>
      <AppMenu setResetData={setResetData} authenticatedUser={authenticatedUser} />
    </>
  );
};
export default AppHeader;
