import { useEffect, useState } from "react";
import readFromDB from "../../firebase/readFromDB";
import "../../styles/ordersList.css";
import { useNavigate } from "react-router";
import { ROLE_DEVELOPPER, ROLE_MANAGER, ROLE_ADMINISTRATOR } from "../../dataBase/users/roleTypes";
const OrderLists = ({ setOrdersList, setStatus, status, authenticatedUser }) => {
  const [orderData, setOrderData] = useState({}); // Initialize state for order data
  const [currentStatus, setCurrentStatus] = useState("all");
  const navigate = useNavigate();
  // Function to filter orderData based on current status
  const filter = (orderData, type) => {
    // If type is 'all', return the entire orderData without filtering
    if (type === "all") {
      return orderData;
    } 

    // Otherwise, filter the items based on the status
    return Object.keys(orderData).reduce((filteredOrders, key) => {
      if (orderData[key].status.status === type) {
        filteredOrders[key] = orderData[key]; // Add the matching item to the new object
      }
      return filteredOrders;
    }, {}); // Start with an empty object
  };

  // Handle menu click and update currentStatus
  const menuClickHandler = (e) => {
    const selectedStatus = e.target.getAttribute("name");
    setCurrentStatus(selectedStatus); // Update currentStatus state
  };

  // Handle click on an order item and navigate to the result page
  const onClickHandler = (orderInfo) => {
    setOrdersList({
      state: true,
      orderInfo: orderInfo,
    });
    setStatus({
      ...status,
      status: orderInfo.status.status,
    });
    navigate("/result");
  };

  // Fetch order data from the database
  const fetchData = async () => {
    try {
      const data = await readFromDB("orders"); // Await the promise to resolve
      if (data) {
        const parsedData = Object.keys(data).reduce((acc, key) => {
          acc[key] = JSON.parse(data[key]); // Parse each JSON string
          return acc;
        }, {});
        if (
          authenticatedUser.role === ROLE_ADMINISTRATOR ||
          authenticatedUser.role === ROLE_DEVELOPPER ||
          authenticatedUser.role === ROLE_MANAGER
        ) {
          setOrderData(parsedData); // Update state with parsed data
        } else {
          const newData = Object.keys(parsedData)
            .filter((order) => parsedData[order].customer.salesRep === authenticatedUser.userId)
            .reduce((result, key) => {
              result[key] = parsedData[key];
              return result;
            }, {});
          setOrderData(newData);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error); // Handle any errors
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div className="orders-list-wrapper">
      <div className="order-list-menu">
        <div className="menu-button" name="all" onClick={menuClickHandler}>
          All
        </div>
        <div className="menu-button" name="quotation" onClick={menuClickHandler}>
          <span className="icon quotation-orders-list"></span>Quotation
        </div>
        <div className="menu-button" name="sales" onClick={menuClickHandler}>
          <span className="icon sales-orders-list"></span>Sales
        </div>
        <div className="menu-button" name="measurement" onClick={menuClickHandler}>
          <span className="icon measurement-orders-list"></span> Measurement
        </div>
        <div className="menu-button" name="manufacturing" onClick={menuClickHandler}>
          <span className="icon manufacturing-orders-list"></span>Manufacturing
        </div>
        <div className="menu-button" name="shipment" onClick={menuClickHandler}>
          <span className="icon shipment-orders-list"></span>Shipment
        </div>
        <div className="menu-button" name="installation" onClick={menuClickHandler}>
          <span className="icon installation-orders-list"></span>Installation
        </div>
        <div className="menu-button" name="payment" onClick={menuClickHandler}>
          <span className="icon payment-orders-list"></span>Payment
        </div>
        <div className="menu-button" name="finished" onClick={menuClickHandler}>
          <span className="icon finished-orders-list"></span>Finished
        </div>
      </div>

      <div className="order-list-container">
        {Object.keys(filter(orderData, currentStatus)).length > 0 ? (
          <ul className="orders-list">
            {Object.keys(filter(orderData, currentStatus))
              .reverse()
              .map((orderId) => (
                <li key={orderId} onClick={() => onClickHandler(orderData[orderId])}>
                  <span className={`icon ${orderData[orderId].status.status}-orders-list`}></span>
                  <p className="status">{orderData[orderId].status.status}</p>
                  <p className="salesrep">{orderData[orderId].status.salesRep}</p>
                  <p className="po">{orderId}</p>
                  <p className="name"> {orderData[orderId].customer.name}</p>
                  <p className="phone"> {orderData[orderId].customer.phone}</p>
                  <p className="postal">{orderData[orderId].customer.postalCode}</p>
                  <p className="address">{orderData[orderId].customer.address}</p>
                </li>
              ))}
          </ul>
        ) : (
          <p>No orders found.</p>
        )}
      </div>
    </div>
  );
};

export default OrderLists;
