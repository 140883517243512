import React from "react";
import { useState, useEffect } from "react";
import readFromDB from "../../firebase/readFromDB";
import "../../styles/reports.css";
const Report = () => {
  // const [orderData, setOrderData] = useState({});

  const [filteredOrder, setFilteredOrder] = useState({
    quotation: [],
    sales: [],
    measurement: [],
    manufacturing: [],
    shipment: [],
    installation: [],
    payment: [],
    finished: [],
  });

  // Fetch order data from the database
  const fetchData = async () => {
    try {
      const data = await readFromDB("orders"); // Await the promise to resolve
      if (data) {
        const parsedData = Object.keys(data).reduce((acc, key) => {
          acc[key] = JSON.parse(data[key]); // Parse each JSON string
          return acc;
        }, {});

        // Categorize parsedData into different arrays based on status
        const categorizedData = Object.keys(parsedData).reduce(
          (acc, key) => {
            const status = parsedData[key].status.status;
            if (acc[status]) {
              acc[status].push(parsedData[key]);
            }
            return acc;
          },
          {
            quotation: [],
            sales: [],
            measurement: [],
            manufacturing: [],
            shipment: [],
            installation: [],
            payment: [],
            finished: [],
          }
        );

        // Set the categorized data to state or use it
        setFilteredOrder(categorizedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error); // Handle any errors
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div className="reports-wrapper">
      <div className="total-repport">
        <div className="total-card">
          <span>Total Quotation</span>
          <span>{filteredOrder.quotation.length}</span>
        </div>
        <div className="total-card">
          <span>Total Sales</span>
          <span>
            {filteredOrder.sales.length +
              filteredOrder.measurement.length +
              filteredOrder.manufacturing.length +
              filteredOrder.shipment.length +
              filteredOrder.installation.length +
              filteredOrder.payment.length +
              filteredOrder.finished.length}
          </span>
        </div>
        <div className="total-card">
          <span>Convert Ratio</span>
          <span>
            {`${(
              ((filteredOrder.sales.length +
                filteredOrder.measurement.length +
                filteredOrder.manufacturing.length +
                filteredOrder.shipment.length +
                filteredOrder.installation.length +
                filteredOrder.payment.length +
                filteredOrder.finished.length) /
                (filteredOrder.sales.length +
                  filteredOrder.measurement.length +
                  filteredOrder.manufacturing.length +
                  filteredOrder.shipment.length +
                  filteredOrder.installation.length +
                  filteredOrder.payment.length +
                  filteredOrder.finished.length +
                  filteredOrder.quotation.length)) *
              100
            ).toFixed(2)} %`}
          </span>
        </div>
      </div>
      <div className="total-ongoing">
        <div className="total-card">
          <span>Sales</span>
          <span>{filteredOrder.sales.length}</span>
        </div>
        <div className="total-card">
          <span>Measurement</span>
          <span>{filteredOrder.measurement.length}</span>
        </div>
        <div className="total-card">
          <span>Manufacturing</span>
          <span>{filteredOrder.manufacturing.length}</span>
        </div>
        <div className="total-card">
          <span>Delievery</span>
          <span>{filteredOrder.shipment.length}</span>
        </div>
        <div className="total-card">
          <span>Installation</span>
          <span>{filteredOrder.installation.length}</span>
        </div>
        <div className="total-card">
          <span>Payment</span>
          <span>{filteredOrder.payment.length}</span>
        </div>
        <div className="total-card">
          <span>Finished</span>
          <span>{filteredOrder.finished.length}</span>
        </div>
      </div>
    </div>
  );
};

export default Report;
